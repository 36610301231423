import Link from 'next/link'
import Image from 'next/image'

const ComingSoon = () => {
  return (
    <div className="flex flex-col">
      <main className="">
        <div className="mx-auto mt-10 max-w-4xl px-5 text-center leading-8">
          <h1 className="text-3xl md:text-6xl">Coming soon</h1>
          <p className="mt-6 text-base">
            Thank you for visiting guardianwell.com, your home for well-being resources. We&apos;re
            working on the finishing touches of our new website experience. Stay tuned for exciting
            updates!
          </p>
          <p className="mt-4 text-base">
            Looking for your Wellthy benefits?
            <Link
              className="ml-1 font-bold text-navy underline"
              href="https://join.wellthy.com/guardian-members"
            >
              Find them here.
            </Link>
          </p>
          <div className="relative mb-8 mt-8 w-full">
            <Image alt="" className="!relative object-cover" src="/coming-soon.png" fill />
          </div>
        </div>
      </main>
    </div>
  )
}

export default ComingSoon
